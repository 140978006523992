import { FaWhatsapp, FaInstagram, FaBehance } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

import style from "./Social.module.scss";

export default function Social({customColor}) {
    const social = [
        {
            href: "https://wa.link/zzpw1g",
            iconComp: <FaWhatsapp size={30} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = customColor ? customColor : "white"} />
        },
        {
            href: "https://www.instagram.com/whereismybrand.mx/",
            iconComp: <FaInstagram size={30} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = customColor ? customColor : "white"} />
        },
        {
            href: "mailto:info@whereismybrand.mx",
            iconComp: <IoMdMail size={30} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = customColor ? customColor : "white"} />
        },
        {
            href: "https://www.behance.net/whereismybrand?tracking_source=search_users%7Cwhere%20is%20my%20brand",
            iconComp: <FaBehance size={30} onMouseOver={({ target }) => target.style.color = "#ff7700"} onMouseOut={({ target }) => target.style.color = customColor ? customColor : "white"} />
        },
    ]

    return (
        <div className={style.social__container}>
            {
                social.map((s, i) => (
                    <a
                        key={i}
                        href={s.href}
                        target={"_blank"}
                        rel="noreferrer"
                        className={`${style.sn__icon}`}
                        arial-label="redes sociales"
                    >
                        {s.iconComp}
                    </a>
                ))
            }
        </div>
    );
}