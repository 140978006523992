import { FaWhatsapp, FaInstagram, FaBehance } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Link from "next/link";

import iso from "../../public/img/iso_footer.png"

export default function Footer() {
    const social = [
        {
            href: "https://wa.link/zzpw1g",
            iconComp: <FaWhatsapp size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#FF7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "https://www.instagram.com/whereismybrand.mx/",
            iconComp: <FaInstagram size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#FF7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "mailto:info@whereismybrand.mx",
            iconComp: <IoMdMail size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#FF7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
        {
            href: "https://www.behance.net/whereismybrand?tracking_source=search_users%7Cwhere%20is%20my%20brand",
            iconComp: <FaBehance size={30} color={"white"} onMouseOver={({ target }) => target.style.color = "#FF7700"} onMouseOut={({ target }) => target.style.color = "white"} />
        },
    ]

    return (
        <footer className="container-fluid bg-black py-5" style={{ paddingLeft: '30px', paddingRight: '40px' }}>
            <div className="container text-white" >
                <div className="row footer-text-align">
                    <div className="col-md-3 mb-4 mb-md-0">
                        <Link href={'/'}>
                            <img src={iso?.src} alt="isotipo" className="w-50 mb-4 d-none d-md-flex pointer" />
                        </Link>
                        <Link href={'/'}>
                            <img src={iso?.src} alt="isotipo" className="w-25 mx-auto mb-4 d-block d-md-none" />
                        </Link>
                        <div className="w-50 mx-auto mx-md-0 d-flex justify-content-between align-items-center">
                            {
                                social.map((s, i) => (
                                    <a
                                        key={`social-${i}`}
                                        href={s.href}
                                        target={"_blank"}
                                        rel="noreferrer"
                                        className="sn-icon"
                                        aria-label="redes-sociales"
                                    >
                                        {s.iconComp}
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h5 className="fs-5 fw-bold">
                            CONTÁCTANOS
                        </h5>
                        <p className="mb-0">
                            <a style={{ textDecoration: 'none', color: 'white' }} href="mailto:info@whereismybrand.com.mx" aria-label="correo" className="hover-orange"> info@whereismybrand.mx</a>
                        </p>
                        <p className="mb-0">
                            <a style={{ textDecoration: 'none', color: 'white' }} href="https://goo.gl/maps/uVdEzbGBEtU71XvJ8" target='_blank' rel="noreferrer" aria-label="direccion" className="hover-orange"> Farallón 206, Jardines del Moral, León, Guanajuato</a>
                        </p>
                        <p className="mb-4">
                            <a style={{ textDecoration: 'none', color: 'white' }} href="https://wa.link/zzpw1g" aria-label="telefono" className="hover-orange">477 309 1387</a>
                        </p>
                    </div>
                    <div className="col-md-2">
                        <h5 className="fs-5 fw-bold">
                            MENÚ
                        </h5>
                        <Link href='/'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="inicio">Inicio</a>
                        </Link> <br />
                        <Link href='/nosotros'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="nosotros">Nosotros</a>
                        </Link> <br />
                        <Link href='/servicios'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="servicios">Servicios</a>
                        </Link> <br />
                        <Link href='/portafolio'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="portafolio">Portafolio</a>
                        </Link> <br />
                        <Link href='/contacto'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="contacto">Contacto</a>
                        </Link> <br />
                    </div>
                    <div className="col-md-3 mt-4 mt-md-0">
                        <h5 className="fs-5 fw-bold">
                            POLÍTICAS
                        </h5>
                        <Link href='/politica-de-privacidad'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="privacidad">Privacidad</a>
                        </Link> <br />
                        <Link href='/terminos-y-condiciones'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="terminos">Términos y condiciones</a>
                        </Link> <br />
                        <Link href='/cookies'>
                            <a className="text-white text-decoration-none hover-orange" aria-label="cookies">Cookies</a>
                        </Link> <br />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row text-white mt-4" style={{ fontSize: '80%' }}>
                    <div className="col-md-4 text-center text-md-start">
                        <small >
                            Copyright © 2023 Where is my Brand? Todos los derechos reservados.
                        </small>
                    </div>
                    <div className="col-md-4 text-center">
                        <small>
                            Powered by <a style={{ color: 'white', fontWeight: 'bold' }} href={'https://vizer.tech'} target="_blank" rel="noreferrer" aria-label="vizer tech">VIZER</a>. Designed by <span style={{ fontWeight: 'bold' }}>Where is my brand?</span>
                        </small>
                    </div>
                    <div className="col-md-4 text-center text-md-end hover-underline">
                        <small>
                            <a style={{ textDecoration: 'none', color: 'white' }} href="/sitemap.xml" aria-label="mapa del sitio">Mapa del sitio</a>
                        </small>
                    </div>
                </div>
            </div>
        </footer>
    );
}